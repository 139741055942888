import React from 'react'
import cls from './LoadingSpinner.module.scss'
import {Spin} from "antd";


const LoadingSpinner: React.FC = () => {
  return (
    <div className={cls.LoadingPage}>
      <Spin size='large'/>
    </div>
  );
}

export default LoadingSpinner
